/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@use '@angular/material' as mat;

@include mat.core();

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:300,400,500');

@font-face {
  font-family: 'Gilroy';
  src: url(assets/fonts/Gilroy-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(assets/fonts/Gilroy-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(assets/fonts/Gilroy-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(assets/fonts/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(assets/fonts/Gilroy-Heavy.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

$fontConfig: (
  headline-1: mat.define-typography-level(112px, 112px, 300, 'Gilroy', -0.0134em),
  headline-2: mat.define-typography-level(56px, 56px, 500, 'Gilroy', -0.0089em),
  headline-3: mat.define-typography-level(45px, 48px, 500, 'Gilroy', 0em),
  headline-4: mat.define-typography-level(34px, 40px, 500, 'Gilroy', 0.0074em),
  headline-5: mat.define-typography-level(24px, 32px, 500, 'Playfair Display', 0em),
  headline-6: mat.define-typography-level(20px, 32px, 500, 'Playfair Display', 0.0075em),
  subtitle-1: mat.define-typography-level(16px, 28px, 400, 'Gilroy', 0.0094em),
  subtitle-2: mat.define-typography-level(14px, 24px, 500, 'Gilroy', 0.0067em),
  body-1: mat.define-typography-level(14px, 24px, 500, 'Gilroy', 0.0179em),
  body-2: mat.define-typography-level(14px, 20px, 500, 'Gilroy', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Gilroy', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 500, 'Gilroy', 0.0333em),
  overline: mat.define-typography-level(inherit, 1.125, 500, 'Gilroy', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #111111;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #f2f2f2;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#f2f2f2, 0.04);
$light-bg-alpha-12:   rgba(#f2f2f2, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Theme Config
$mat-primary: (
  main: #707070,
  lighter: #d4d4d4,
  darker: #535353,
  200: #707070, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #0c83e1,
  lighter: #e8f5ff,
  darker: #0666d5,
  200: #0c83e1, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #ce2525,
  lighter: #f0bebe,
  darker: #bc1616,
  200: #ce2525, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);;

// $theme-primary: mat.define-palette(mat.$indigo-palette, 500);
// $theme-accent: mat.define-palette(mat.$blue-palette);
// $theme-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme((
 color: (
   primary: $theme-primary,
   accent: $theme-accent,
   warn: $theme-warn,
 ),
 typography: $fontConfig,
 density: 0,
));

// Theme Init
@include mat.all-component-themes($theme);

// Specific component overrides, pieces that are not in line with the general theming
body {
  --primary-color: #707070;
  --primary-lighter-color: #d4d4d4;
  --primary-darker-color: #535353;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --black_text_color: #111;
  --blue_color: #0C83E1;
  --blue_soft_color: #E8F5FF;
  --grey_text_color: #888888;
  --grey_soft_background_color: #FAFAFA;
  --yellow: #E3B163;
  --green: #6BC434;
  --red: #CE2525;
  --accent-color: #0c83e1;
  --accent-lighter-color: #e8f5ff;
  --accent-darker-color: #0666d5;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
  --warn-color: #ce2525;
  --warn-lighter-color: #f0bebe;
  --warn-darker-color: #bc1616;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

.mat-mdc-tab-link-container {
  border-bottom-color: #e0e2ec !important;
}

.mat-mdc-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
  height: unset !important;
}

.mat-mdc-chip.mat-warn {
  background-color: var(--red);
  color: white;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Gilroy';
  }
}

.mat-mdc-paginator-container {
  min-height: unset !important;
}

.mat-mdc-paginator-container {
  width: max-content !important;
}

.mat-mdc-paginator-outer-container {
  display: inline-flex !important;
}

.mat-mdc-paginator {
  display: inline-block !important;
  width: fit-content !important;
  width: -moz-fit-content !important;
  flex-shrink: 0 !important;
}

.mat-mdc-table {
  width: 100%;
  height: max-content;
}

.mat-mdc-table .mat-mdc-row:nth-child(odd){
  background-color: var(--grey_soft_background_color);
}

.mat-mdc-button.mat-black,
.mat-mdc-icon-button.mat-black,
.mat-mdc-outlined-button.mat-black {
  color: black;
}

.mat-mdc-unelevated-button.mat-black,
.mat-mdc-raised-button.mat-black,
.mat-mdc-fab.mat-black,
.mat-mdc-mini-fab.mat-black {
  background-color: black;
  color: white;
}

.mat-mdc-button.mat-success,
.mat-mdc-icon-button.mat-success,
.mat-mdc-outlined-button.mat-success {
  color: var(--green);
}

.mat-mdc-unelevated-button.mat-success,
.mat-mdc-raised-button.mat-success,
.mat-mdc-fab.mat-success,
.mat-mdc-mini-fab.mat-success {
  background-color: var(--green);
  color: white;
}

mat-icon.mat-success {
  color: var(--green);
}

.mat-grey {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.mat-mdc-button.mat-grey,
.mat-mdc-icon-button.mat-grey,
.mat-mdc-outlined-button.mat-grey {
  color: var(--grey_text_color);
}

.mat-mdc-unelevated-button.mat-grey,
.mat-mdc-raised-button.mat-grey,
.mat-mdc-fab.mat-grey,
.mat-mdc-mini-fab.mat-grey {
  background-color: var(--grey_soft_background_color);
}

.mat-mdc-chip {
  border-radius: 150px !important;
  padding: 4px 20px !important;
  min-height: unset !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-yellow {
  background-color: var(--yellow);
  color: black;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-success {
  background-color: var(--green);
  color: white;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: unset !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-label-content {
  text-transform: uppercase;
  font-weight: 500;
}

.mat-mdc-tab-header {
  margin-bottom: 20px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  transform: translateY(15px);
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
mat-checkbox {
  max-width: 100%;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
.mat-checkbox-layout {
  white-space: unset !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
.mat-checkbox-inner-container {
  margin: 0 8px 0 0 !important;
  transform: translateY(2px);
}

.mat-mdc-form-field {
  max-width: 100%;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-header-pagination {
  z-index: 1 !important;
}

.mat-mdc-chip.mat.standard-chip::after,
.mat-mdc-input-element[type=date]::after,
.mat-input-element[type=datetime]::after,
.mat-input-element[type=datetime-local]::after,
.mat-input-element[type=month]::after,
.mat-input-element[type=week]::after,
.mat-input-element[type=time]::after {
  content: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
mat-form-field.readonly .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
mat-form-field.readonly .mat-input-element,
mat-form-field-type-mat-native-select.readonly .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-header-row {
  font-size: 13px !important;
}

.mat-mdc-unelevated-button,
.mat-mdc-button,
.mdc-button {
  border-radius: 9999px !important; // default material
  padding: 0 24px !important;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.006rem !important;
}

.mat-mdc-unelevated-button.mat-black:not(:disabled),
.mat-mdc-button.mat-black:not(:disabled) {
  background-color: black;
  color: white;
}

.small-icon-button {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  line-height: 32px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 1px !important;
}

mat-form-field.noborder .mdc-notched-outline__leading,
mat-form-field.noborder .mdc-notched-outline__notch,
mat-form-field.noborder .mdc-notched-outline__trailing {
  border-color: transparent !important;
}

.mat-accent.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1a1b1f !important;
  --mdc-switch-selected-focus-state-layer-opacity: .12 !important;
  --mdc-switch-selected-hover-state-layer-opacity: .08 !important;
  --mdc-switch-selected-pressed-state-layer-opacity: .12 !important;
  --mdc-switch-unselected-focus-state-layer-opacity: .12 !important;
  --mdc-switch-unselected-hover-state-layer-opacity: .08 !important;
  --mdc-switch-unselected-pressed-state-layer-opacity: .12 !important;
  --mdc-switch-selected-focus-state-layer-color: var(--blue_color) !important;
  --mdc-switch-selected-handle-color: #ffffff !important;
  --mdc-switch-selected-hover-state-layer-color: var(--blue_color) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--blue_color) !important;
  --mdc-switch-selected-focus-handle-color: #e0e0ff !important;
  --mdc-switch-selected-hover-handle-color: #e0e0ff !important;
  --mdc-switch-selected-pressed-handle-color: #e0e0ff !important;
  --mdc-switch-selected-focus-track-color: var(--blue_color) !important;
  --mdc-switch-selected-hover-track-color: var(--blue_color) !important;
  --mdc-switch-selected-pressed-track-color: var(--blue_color) !important;
  --mdc-switch-selected-track-color: var(--blue_color) !important;
  --mdc-switch-disabled-selected-handle-color: #fdfbff !important;
  --mdc-switch-disabled-selected-icon-color: #1a1b1f !important;
  --mdc-switch-disabled-selected-track-color: #1a1b1f !important;
  --mdc-switch-disabled-unselected-handle-color: #1a1b1f !important;
  --mdc-switch-disabled-unselected-icon-color: #e0e2ec !important;
  --mdc-switch-disabled-unselected-track-color: #e0e2ec !important;
  --mdc-switch-selected-icon-color: #00006e !important;
  --mdc-switch-unselected-focus-handle-color: #44474e !important;
  --mdc-switch-unselected-focus-state-layer-color: #1a1b1f !important;
  --mdc-switch-unselected-focus-track-color: #e0e2ec !important;
  --mdc-switch-unselected-handle-color: #74777f !important;
  --mdc-switch-unselected-hover-handle-color: #44474e !important;
  --mdc-switch-unselected-hover-state-layer-color: #1a1b1f !important;
  --mdc-switch-unselected-hover-track-color: #e0e2ec !important;
  --mdc-switch-unselected-icon-color: #e0e2ec !important;
  --mdc-switch-unselected-pressed-handle-color: #44474e !important;
  --mdc-switch-unselected-pressed-state-layer-color: #1a1b1f !important;
  --mdc-switch-unselected-pressed-track-color: #e0e2ec !important;
  --mdc-switch-unselected-track-color: #e0e2ec !important;
  --mat-switch-track-outline-color: #74777f !important;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f !important;
}

.mat-mdc-progress-spinner.disabled .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.disabled .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--grey_text_color);
}

.mat-mdc-progress-spinner.inprogress .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.inprogress .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--blue_color);
}

.mat-mdc-progress-spinner.finished .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.finished .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--green);
}

.mat-mdc-progress-spinner.overtime .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.overtime .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--red);
}

.mat-mdc-progress-spinner.start .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.start .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(245, 245, 245);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: 9999px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 38px !important;
}
