/*
Generic Highlight.js Styles for Any Language
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

/* Text styles */
.hljs-comment,
.hljs-quote {
  color: #5c6370;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-section,
.hljs-link {
  color: #c678dd;
}

.hljs-built_in,
.hljs-doctag,
.hljs-title,
.hljs-literal,
.hljs-meta {
  color: #d19a66;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #98c379;
}

.hljs-number,
.hljs-symbol,
.hljs-bullet {
  color: #d19a66;
}

.hljs-variable,
.hljs-template-variable,
.hljs-class .hljs-title,
.hljs-type,
.hljs-tag .hljs-title,
.hljs-selector-class {
  color: #e6c07b;
}

.hljs-function,
.hljs-function .hljs-keyword,
.hljs-function .hljs-title {
  color: #61afef;
}

.hljs-keyword,
.hljs-params,
.hljs-section,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-meta,
.hljs-tag,
.hljs-attr,
.hljs-link,
.hljs-attribute,
.hljs-doctag,
.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-meta-string,
.hljs-number,
.hljs-symbol,
.hljs-bullet,
.hljs-variable,
.hljs-template-variable,
.hljs-class .hljs-title,
.hljs-type,
.hljs-selector-class,
.hljs-function .hljs-keyword,
.hljs-function .hljs-title {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-code,
.hljs-title {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

/* Line numbers */
.hljs-ln-numbers {
  color: #5c6370;
  background-color: #282c34;
  padding-right: 10px;
  text-align: right;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.hljs-ln-numbers:after {
  content: '';
}

.hljs-ln-code {
  position: relative;
  padding-left: 15px;
  display: block;
}

.hljs-ln-comment {
  color: #5c6370;
}

.hljs-ln-keyword,
.hljs-ln-selector-tag,
.hljs-ln-selector-id,
.hljs-ln-section,
.hljs-ln-link {
  color: #c678dd;
}

.hljs-ln-built_in,
.hljs-ln-doctag,
.hljs-ln-title,
.hljs-ln-literal,
.hljs-ln-meta {
  color: #d19a66;
}

.hljs-ln-string,
.hljs-ln-regexp,
.hljs-ln-addition,
.hljs-ln-attribute,
.hljs-ln-meta-string {
  color: #98c379;
}

.hljs-ln-number,
.hljs-ln-symbol,
.hljs-ln-bullet {
  color: #d19a66;
}

.hljs-ln-variable,
.hljs-ln-template-variable,
.hljs-ln-class .hljs-ln-title,
.hljs-ln-type,
.hljs-ln-tag .hljs-ln-title,
.hljs-ln-selector-class {
  color: #e6c07b;
}

.hljs-ln-function,
.hljs-ln-function .hljs-ln-keyword,
.hljs-ln-function .hljs-ln-title {
  color: #61afef;
}

.hljs-ln-keyword,
.hljs-ln-params,
.hljs-ln-section,
.hljs-ln-selector-tag,
.hljs-ln-selector-id,
.hljs-ln-meta,
.hljs-ln-tag,
.hljs-ln-attr,
.hljs-ln-link,
.hljs-ln-attribute,
.hljs-ln-doctag,
.hljs-ln-string,
.hljs-ln-regexp,
.hljs-ln-addition,
.hljs-ln-meta-string,
.hljs-ln-number,
.hljs-ln-symbol,
.hljs-ln-bullet,
.hljs-ln-variable,
.hljs-ln-template-variable,
.hljs-ln-class .hljs-ln-title,
.hljs-ln-type,
.hljs-ln-selector-class,
.hljs-ln-function .hljs-ln-keyword,
.hljs-ln-function .hljs-ln-title {
  font-weight: bold;
}

/*
Dart Syntax Highlighting for Highlight.js
*/

/* Keywords */
.hljs-keyword {
  color: #2b91af;
}

/* Built-in types */
.hljs-built_in {
  color: #0086b3;
}

/* Strings */
.hljs-string {
  color: #1f7a7a;
}

/* Numbers */
.hljs-number {
  color: #994e2d;
}

/* Comments */
.hljs-comment,
.hljs-meta {
  color: #686868;
  font-style: italic;
}

/* Operators */
.hljs-operator {
  color: #808080;
}

/* Function names */
.hljs-title,
.hljs-function {
  color: #2b91af;
}

/* Class names */
.hljs-type,
.hljs-class .hljs-title {
  color: #994e2d;
}

/* Punctuation */
.hljs-punctuation {
  color: #686868;
}


/*
Markdown Syntax Highlighting for Highlight.js
*/

/* Headings */
.hljs-section,
.hljs-title {
  color: #1A9A00;
}

/* Bold */
.hljs-strong {
  font-weight: bold;
}

/* Italic */
.hljs-emphasis {
  font-style: italic;
}

/* Links */
.hljs-link {
  color: #2274A5;
}

/* Images */
.hljs-image {
  color: #F2A104;
}

/* Code Blocks */
.hljs-code {
  color: #686868;
}

/* Blockquotes */
.hljs-quote {
  color: #8D8D8D;
}

/* Lists */
.hljs-bullet,
.hljs-list {
  color: #FF4C00;
}
