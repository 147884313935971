html, body {
    height: 100%;
    background-color: #F2F2F2;
    font-size: 16px;
}
body { margin: 0; font-family: "Gilroy", "Helvetica Neue", sans-serif; }

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 700;
}

a {
    color: var(--blue_color);
}

p, label {
    color: var(--grey_text_color);
}

#content h2 {
    margin: 10px 0 20px;
}

.card {
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 3px -3px rgba(0,0,0,0.4);
    display: block;
}

.card-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: unset;
    margin: 0 0 20px;
    background-color: transparent;
    border: unset;
    width: 100%;
}

.card-header .card-title {
    display: inline-block;
    margin-right: auto;
}

.card-header .card-title h2 {
    margin-bottom: unset;
    margin-top: unset;
    font-size: 22px;
}

.card-header .card-title .subtitle {
    margin: unset;
    color: var(--grey_text_color);
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

.card-header button {
    margin-left: 20px;
}

.card.action-card {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 15px 25px;
}

.card.action-card mat-icon {
    margin-right: 20px;
    transform: scale(1.5);
}

.card.action-card .card-header,
.card.action-card .card-header .card-title  {
    margin-bottom: unset;
}

#content .card.action-card .card-header .card-title h2 {
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
}

#content .page-title {
    margin: 0px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

#content .page-title h1 {
    font-weight: 800;
    margin: unset;
    display: block;
    width: 100%;
}

#content .page-title h1 ~ * {
    display: block;
    margin-right: auto;
    margin-top: 20px;
}

.bisual-table {
    width: 100%;
    display: block;
    overflow-y: auto;
}

.bisual-table-two-columns > .bisual-table-container,
.bisual-table-container {
    overflow-x: scroll;
}

.bisual-table-two-columns > .bisual-table-container > table,
.bisual-table > .bisual-table-container > table {
    min-width: 800px;
}

.bisual-table .bisual-table-row-header,
.bisual-table .bisual-table-row {
    display: grid;
    grid-template-columns: repeat(4, minmax(160px, 1fr));
}

.bisual-table .bisual-table-row-header .bisual-table-col,
.bisual-table .bisual-table-row .bisual-table-col {
    padding: 4px 10px;
}

.bisual-table .bisual-table-row:nth-child(odd) {
    background-color: var(--grey_soft_background_color);
}

.bisual-table .bisual-table-row-header {
    box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.4);
    margin-bottom: 4px;
}

.bisual-table .bisual-table-row-header .bisual-table-col {
    font-weight: 500;
}

.bisual-table th.mat-mdc-header-cell {
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
}

.bisual-table th.mat-mdc-header-cell:not(:first-child):not(:last-child) {
    padding-left: 5px;
    padding-right: 5px;
}

td.mat-mdc-cell:not(:first-child):not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.paginator {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px !important;
    flex-wrap: nowrap;
    overflow-y: auto;
    flex-direction: row !important;
}

.paginator mat-form-field:not(:last-child) {
    margin-right: 20px;
}

.paginator mat-form-field {
    max-width: 180px;
}

.paginator mat-form-field.mat-mdc-form-field-type-mat-select:not(.mat-mdc-paginator-page-size-select) {
  margin-bottom: 10px;
}

.paginator .mat-mdc-form-field-subscript-wrapper,
mat-form-field.nobottom .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.paginator mat-form-field.bisual-paginator-select {
    transform: translateY(6px);
}

.col mat-form-field {
    max-width: 100%;
}

.mat-mdc-dialog-container h2.dialog-section {
    margin: 50px 0 20px;
    display: block;
    width: max-content;
    font-size: 18px;
    font-weight: 400;
}

#content {
    padding: 20px;
}

.mat-column-image,
.mat-column-actions {
    width: 70px;
}

.bisual-table-two-columns .field {
    margin-bottom: 20px;
}

.bisual-table-two-columns .field mat-label {
    display: block;
    font-weight: 700;
}

.bisual-table-two-columns .field mat-radio-button {
    display: block;
    max-width: 100%;
}

.status-point {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: black;
    cursor: pointer;
    display: inline-block;
}

.status-point.active {
    background-color: #6BC434;
}

.status-point.inactive {
    background-color: #CE2525;
}

.status-point.cancelled {
    background-color: #E3B163;
}

.status-point.expired {
    background-color: #0C83E1;
}

.status-point.deleted {
    background-color: #535353;
}

.bisual-two-col-masonry {
    display: block;
    column-gap: 1rem;
    columns: 1;
}

.custom-dialog-container  .mat-mdc-dialog-container {
  padding: 0px !important;
}



.bisual-two-col-masonry .card {

}

.dialog-title-container {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.dialog-title-container h1 {
    width: auto;
    display: inline-block;
    margin: 0 auto 0 0;
}

.chart-container {
    display: block;
    width: 100%;
    margin-bottom: 50px;
}

.chart-container h2 {
    display: block;
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
    margin: 0 0 20px !important;
}

#description-container .description p{
  color: black !important;
  font-size: 1em;
  line-height: 1.714;
  font-weight: normal;
  letter-spacing: -0.005em;
  margin-bottom: 6px;
}

#description-container .description span{
  text-decoration: underline;
}

#description-container .description img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

#comments .body img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

#comments .body span{
  text-decoration: underline;
}

#comments .body p{
  color: black !important;
  font-size: 1em;
  line-height: 1.714;
  letter-spacing: -0.005em;
  margin-bottom: 6px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.col-progress .mat-select-value{
  color: var(--ds-text-information,#0747A6) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.col-done .mat-select-value{
  color: var(--ds-text-success,#006644) !important;
}

.row {
    margin-bottom: 10px;
}

.resume .card {
    height: 100%;
}

.legend-labels.horizontal-legend {
    overflow-x: auto !important;
}

.icon-favorite-icon {
  display: inline-block;
  width: 40px;
  white-space: nowrap !important;
}

.icon-favorite-icon mat-icon:nth-child(2) {
  position: relative;
  left: -28px;
  bottom: -8px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  color: #ffb800;
}

.cal-day-selected,
.cal-day-selected:hover {
  background-color: #e8f5ff !important;
}

.card h2, .card h3, .card h4, .card h5, .card h6 {
  font-family: "Gilroy";
  font-size: 18px;
}

@media screen and (min-width: 768px) {
    .bisual-table-two-columns > .bisual-table-container > table,
    .bisual-table > .bisual-table-container > table {
        min-width: auto;
    }

    #content .page-title {
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    #content .page-title h1 {
        display: inline-block;
        width: auto;
    }

    #content .page-title h1 ~ * {
        display: inline-block;
        margin-right: unset;
        margin-top: unset;
        margin-left: 20px;
    }

    #content .page-title h1 + * {
        margin-left: auto;
    }

    #content {
        margin: 0 auto;
        width: 90%;
        max-width: 1200px;
        overflow: hidden;
        height: auto;
    }
}

@media screen and (min-width: 992px) {
    html, body {
        font-size: 14px;
    }

    #content .page-title {
        margin: 10px 0px 40px;
    }

    #content .card-header .card-title h2 {
        margin: unset;
        font-size: 26px;
    }

    #content h2 {
        margin: 40px 0 20px;
    }

    #content h2 {
        margin: 0 0 5px;
    }

    .bisual-two-col-masonry {
        columns: 2;
    }
}

@media screen and (min-width: 1200px) {
    .card {
        padding: 20px 30px;
    }

    .bisual-table-two-columns {
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-gap: 20px;
    }
}

@media screen and (min-width: 1920px) {
    .card {
        padding: 20px 40px;
    }
}
